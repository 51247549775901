<template>
  <div class="footer">
    <div class="footers">
      <img class="pc" src="@/assets/footer2.png" alt="" />
      <img class="h5" src="@/assets//h5/footer.png" alt="" />
      <div class="right">
        <ul>
          <li @click.stop="toHomePage()">
            <div>首页</div>
            <ul id="lists" class="shouye">
              <li @click="toServiceDetail(0)">服务行业</li>
              <li @click="toLawyerDetail(0)">专业团队</li>
              <li @click="toArticle()">涉外动态</li>
              <li @click="toMechanism()">涉外机构</li>
            </ul>
          </li>
          <li>
            <div>服务行业</div>
            <ul id="lists">
              <li
                v-for="(x, i) in serviceList"
                :key="i"
                @click="toServiceDetail(x.id)"
              >
                {{ x.name }}
              </li>
            </ul>
          </li>
          <li>
            <div>专业团队</div>
            <ul id="lists">
              <li @click="toLawyerDetail(0)">团队列表</li>
            </ul>
          </li>
          <li>
            <div>涉外动态</div>
            <ul id="lists">
              <li @click="toArticle()">涉外动态</li>
            </ul>
          </li>
          <li>
            <div>盈科研究</div>
            <ul id="lists">
              <li @click="toMechanism()">盈科研究</li>
            </ul>
          </li>
          <li>
            <div>关于我们</div>
            <ul id="lists" class="lianxi">
              <li>涉外简介</li>
              <li @click="toContentUs()">联系我们</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="end">
      <div class="ends">
        <ul>
          <li>版权所有◎2022 盈科上海涉外服务</li>
          <li class="shu" style="color: #545457">|</li>
          <li>备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备14039671号</a> </li>
          <li class="shu" style="color: #545457">|</li>
          <li>技术支持：盈科总部信息技术部</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serviceList: [], //服务行业
    };
  },
  created() {
    this.getServiceList();
  },
  methods: {
    //获得服务行业
    getServiceList() {
      this.axios
        .get(this.Url.apiUrl + "ServiceScope/GetServiceScope")
        .then((res) => {
          this.serviceList = res.data;
        });
    },

    //跳转服务行业详情
    toServiceDetail(id) {
      this.$router.push({
        path: "/serviceTrade#" + id,
        params: {
          id: id,
        },
      });
    },
    //跳转到律师详情
    toLawyerDetail(id) {
      this.$router.push({
        name: "ProfessionalTeam",
        params: {
          id: id,
        },
      });
    },
    //跳转到涉外机构
    toMechanism() {
      this.$router.push({
        name: "Mechanism",
      });
    },
    //跳转到动态
    toArticle() {
      this.$router.push({
        name: "Dynamic",
      });
    },
    toHomePage() {
      this.$router.push({
        name: "Home",
      });
    },
    //跳转联系我们
    toContentUs() {
      this.$router.push({
        path: "/#contentUs",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  position: relative;
  width: 100%;
  height: 385px;
  background-image: url("~@/assets/footer1.png");
  background-size: 100% 100%;

  .footers {
    display: flex;
    justify-content: space-between;
    padding-top: 45px;
    width: 1200px;
    margin: 0 auto;

    img {
      width: 155px;
      height: 63px;
      object-fit: contain;
    }
    .h5 {
      display: none;
    }

    .right {
      width: 1000px;
      ul {
        color: #ffffff;
        display: flex;
        justify-content: space-evenly;

        li {
          font-weight: bold;

          #lists {
            margin-top: 10px;
            display: flex;
            flex-direction: column;

            li {
              cursor: pointer;
              font-size: 14px;
              font-weight: normal;
              color: #a9acaf;
              line-height: 2.5;
            }
          }
        }
      }
    }
  }

  .end {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);

    .ends {
      width: 1200px;
      margin: 0 auto;
      color: #ffffff;

      ul {
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 14px;
        line-height: 50px;
        a{
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .footer {
    height: 450px;
    .footers {
      padding-top: 10px;
      .pc {
        display: none;
      }
      .h5 {
        display: block;
        width: 80%;
        margin: 0 auto;
      }
      .right {
        div {
          width: 20%;
          font-weight: normal;
          font-size: 14px;
        }
        width: 100%;
        ul {
          width: 95%;
          margin: 0 auto;
          flex-direction: column;
          li {
            display: flex;
            align-items: flex-start;
            font-size: 13px !important;
            margin: 5px 0;
            #lists {
              margin: 0;
            }
            #lists li {
              line-height: normal !important;
              margin: 0 10px;
            }
            .shouye,
            .lianxi {
              flex-direction: row !important;
              justify-content: flex-start;
            }
          }
        }
      }
      width: 100%;
      display: block;
    }
    .end {
      background: rgba(0, 0, 0, 0);
      height: 70px;
      .ends {
        color: #000;
        width: 100%;
        ul {
          flex-direction: column;
          color: #ffffff;
          overflow: hidden;
          font-size: 13px;
          li {
            line-height: normal;
            white-space: nowrap;
          }
          .shu {
            display: none;
          }
        }
      }
    }
  }
}
</style>