import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {path: '/', redirect: '/home'},
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/pages/page_home.vue')
  },
  {
    path: '/industry',
    name: 'Industry',
    component: () => import( '../views/pages/page_industry.vue')
  },
  {
    path: '/serviceTrade',
    name: 'serviceTrade',
    component: () => import( '../views/serviceTrade/index.vue')
  },
  {
    path: '/ProfessionalTeam',
    name: 'ProfessionalTeam',
    component: () => import( '../views/ProfessionalTeam/index.vue')
  },
  {
    path: '/mechanism',
    name: 'Mechanism',
    component: () => import( '../views/mechanism/index.vue')
  },
  {
    path: '/mechanism/:id.html',
    name: 'Mechanism-detail',
    component: () => import( '../views/mechanism/mechanismsg.vue')
  },
  {
    path: '/dynamic',
    name: 'Dynamic',
    component: () => import( '../views/dynamic/index.vue')
  },
  {
    path: '/dynamicmsg/:id.html',
    name: 'Dynamic-detail',
    component: () => import( '../views/dynamic/dynamicmsg.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
