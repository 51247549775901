<template>
  <div class="home">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/views/components/header';
import Footer from '@/views/components/footer';
export default {
  name: "Home",
  components:{
    Header,
    Footer
  },
  data(){
    return{
      nowMenu:1,
      menuList:[
        {
          title:'首页',
          value:1,
          name:'Home',
        },
        {
          title:'服务行业',
          value:2,
        },
        {
          title:'专业团队',
          value:3,
        },
        {
          title:'盈科机构',
          value:4,
        },
        {
          title:'盈科动态',
          value:5,
        },
        {
          title:'联系我们',
          value:6,
        },
      ],
    }
  },
  methods:{
    menuChange(value,name){
      this.nowMenu = value;
      this.$router.push({name})
      console.log(value,name)
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/home.scss";
</style>