<template>
  <div class="header">
    <div class="head_title">
      <div class="head_left">
        <img src="@/assets/dianhua.png" alt="" />
        <div>{{phone}}</div>
      </div>
      <div class="head_center">
        <img src="@/assets/dizhi.png" alt="" />
        <div>{{address}}</div>
      </div>
      <div class="head_right">
        <img src="@/assets/weikehu.png" alt="" />
      </div>
    </div>
    <div class="menu">
      <div class="menus">
        <div class="menus_left">
          <img src="@/assets/logo.png" alt="" />
        </div>
        <div class="menus_right">
          <img src="@/assets/caidan.png" alt="" />
          <ul>
            <li
              :class="{ pointer: $store.state.navChange == item.value }"
              v-for="item in menuList"
              :key="item.value"
              @click="menuChange(item.url, item.value)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
      <div class="nav">
        <div class="navs">
          <div class="left">
            <img src="@/assets/logo.png" alt="" />
          </div>
          <div class="right">
            <img @click="showList" src="@/assets/caidan.png" alt="" />
          </div>
        </div>
        <ul v-show="isShow"   @click="close">
          <li 
            :class="{ pointer: $store.state.navChange == item.value }"
            v-for="item in menuList"
            :key="item.value"
            @click="menuChange(item.url, item.value)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      nowMenu: 1,
      phone:"",
      address:"",
      menuList: [
        {
          title: "首页",
          value: 1,
          url: "/",
        },
        {
          title: "服务行业",
          value: 2,
          url: "/serviceTrade",
        },
        {
          title: "专业团队",
          value: 3,
          url: "/ProfessionalTeam",
        },
        {
          title: "盈科研究",
          value: 4,
          url: "/mechanism",
        },
        {
          title: "盈科动态",
          value: 5,
          url: "/dynamic",
        },
        {
          title: "联系我们",
          value: 6,
          url: "/#contentUs",
        },
      ],
    };
  },
  mounted(){
    this.getPhoneAndAddress()
  },
  methods: {
    getPhoneAndAddress(){
       this.axios({
        type: "get",
        url: this.Url.apiUrl + "SystemOption/GetSetting?name=all",
      }).then((res) => {
        console.log(res)
        if(res.data&&res.data.length>0){
          res.data.forEach(p=>{
            if(p.name=="phone")this.phone = p.value
            if(p.name=="address")this.address = p.value
          })
          console.log(this.phone,this.address)
        }
      });
    },
    menuChange(urlItem, item) {
      this.$store.commit('SET_NAV',item)
      this.nowMenu = item;
      this.$router.push({ path: urlItem });
    },
    showList() {
      this.isShow = !this.isShow;
    },
    close(){
      this.isShow = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: auto !important;
  z-index: 999;
  position: fixed;
}
.head_title {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 55px;
  margin: 0 auto;
  background-color: #fff;

  .head_left {
    display: flex;
    height: 55px;
    white-space: nowrap;
    overflow: hidden;

    img {
      height: 20px;
      margin-top: 18px;
    }

    div {
      line-height: 55px;
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .head_center {
    margin-left: 30px;
    display: flex;
    white-space: nowrap;
    overflow: hidden;

    img {
      height: 20px;
      margin-top: 18px;
    }

    div {
      line-height: 55px;
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .head_right {
    margin-top: 18px;
    margin-left: 190px;
  }
}

.menu {
  width: 100%;
  height: 90px;
  background: rgba(128, 154, 190, 0.5);

  .menus {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menus_left {
    }

    .menus_right {
      img {
        display: none;
      }
      ul {
        display: flex;

        li {
          text-align: center;
          width: 125px;
          height: 90px;
          color: white;
          line-height: 90px;
          cursor: pointer;
        }

        .pointer {
          background-color: #1164ff;
        }

        li:hover {
          background-color: #1164ff;
        }
      }
    }
  }
  .nav {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .header {
    position: relative;
    margin-bottom: -70px;
    .head_title {
      .head_left,
      .head_center {
        display: none;
      }
      .head_right {
        margin: 18px auto;
        width: 80%;
        img {
          width: 100%;
        }
      }
    }
    .menu {
      height: auto;
      .menus {
        display: none;
      }
      .nav {
        display: block;
        .navs {
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: 100%;
          .left {
            img {
              width: 250px;
            }
          }
          .right {
            img {
              width: 25px;
            }
          }
        }
        ul{
          background: rgba(0, 0, 0, 0.6);
          width: 100%;
          position: absolute;
          z-index: 999;
          li{
            padding-left: 30px;
            color: #FFFFFF;
            border-bottom: 1px solid gray;
            line-height: 40px;
            
          }
        }
      }
    }
  }
}
</style>