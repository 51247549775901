<template>
  <div id="app">
    <Home />
  </div>
</template>
<script>
import Home from "./views/Home.vue";
export default {
  components: {
    Home,
  },
  mounted() {
    this.initCNZZ();
  },
  methods: {
    initCNZZ() {
      const script = document.createElement('script')
      script.src = `https://tb.53kf.com/code/code/915a9f0e776170d9f09d711d16d540620/1`
      script.id='cnzz'
      script.language = 'JavaScript'
      document.body.appendChild(script)
    },
  },
};
</script>

<style lang="scss">
</style>
